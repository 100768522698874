@import url('../../../../styles/customMediaQueries.css');

.baseColumn {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--n-size-4);

  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  padding: var(--n-size-4);
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;

  @media (width >= 768px) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}

.threeColumns {
  composes: baseColumn;

  @media (width >= 768px) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}

.fourColumns {
  composes: baseColumn;

  @media (width >= 768px) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }
}

.noSidePaddings {
  padding-right: 0;
  padding-left: 0;
}
