.link {
  display: inline;

  hyphens: auto;
  color: var(--marketplaceColor);

  /* fallback option */
  word-break: break-all;

  /* use break-word if available */
  word-break: break-word;
}
