.root {
  hyphens: auto;

  /* fallback option */
  word-break: break-all;

  /* use break-word if available */
  word-break: break-word;
}

.media {
  width: 100%;
  margin-bottom: 0;
  border-radius: var(--radius-md);
}

.text {
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  /* we style global elements only because they are generated by markdown processor */
  & a {
    color: var(--colorGrey700);

    &:hover {
      color: var(--marketplaceColor);
      text-decoration: none;
    }
  }

  & ol {
    margin-top: 0;
    padding-left: 0;
  }

  & li {
    padding: 0;
    font-size: var(--n-font-size-00);
    color: var(--colorGrey700);
    list-style-position: inside;
  }

  & p {
    padding: 0;
  }
}
