.root {
}

.media {
  width: 100%;
  margin-bottom: 0;
  border-radius: var(--radius-md);
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}
