.backgroundImageWrapper {
  position: absolute;
  inset: 0;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
