@import url('../../../../styles/customMediaQueries.css');

.baseColumn {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--n-size-4);

  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  padding: var(--n-size-4);
}

.featuresMain {
  composes: baseColumn;
}

.block {
  display: flex;
  grid-auto-flow: dense;
  flex-direction: column;
  gap: 0;
  align-items: center;

  &:nth-child(even) {
    @media (width >= 768px) {
      flex-direction: row;
    }
  }

  @media (width >= 768px) {
    flex-direction: row-reverse;
    gap: var(--n-size-8);
  }
}

.noSidePaddings {
  padding-right: 0;
  padding-left: 0;
}
