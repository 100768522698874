.articleMain {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--n-size-4);

  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  margin: 0 auto;
  padding: 0 var(--n-size-4);
}

.noSidePaddings {
  padding-right: 0;
  padding-left: 0;
}
