@import url('../../../../styles/customMediaQueries.css');

.root {
  position: relative;

  width: 100%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }
}

.sectionContent {
  position: relative;
  padding: var(--n-size-4) 0;

  @media (width >= 768px) {
    padding: var(--n-size-8) 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  inset: 0;
}
