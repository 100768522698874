.layout {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.topbar {
  position: sticky;
  z-index: var(--zIndexTopbar);
  top: 0;
}

.main {
  display: grid;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}
