@import url('../../../../styles/customMediaQueries.css');

.root {
  border-top: 1px solid #d9d9d9;
}

@media (width >= 1024px) {
  .root > div {
    width: 100%;
    max-width: var(--n-content-max-width);
    margin: 0 auto;
    padding: var(--n-size-5) var(--n-size-10);
  }
}

.footer {
  margin: 0 auto;
  padding: 0 var(--n-size-3);
  border-bottom: 1px solid var(--n-color-border-active);
  border-bottom-width: 0;

  @media (width >= 1024px) {
    padding: 0;
  }
}

.subscribeSection {
  display: grid;
  gap: var(--n-size-3);
}

.privacy {
  margin-top: 20px;
  margin-bottom: 20px;

  /* Body/Body 2/Regular */
  font-family: Montserrat;
  font-size: var(--n-font-size-00);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
}

.footer ul {
  margin-top: 1px;
  padding-inline-start: 0;
  list-style-type: none;
}

.firstSection,
.secondSection,
.thirdSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.firstSection {
  grid-area: firstSection;
}

.secondSection {
  grid-area: secondSection;
}

.thirdSection {
  display: flex;
  grid-area: thirdSection;
  border-bottom: 1px solid var(--n-color-border-active);

  & .privacy {
    text-align: center;
  }
}

.footerTitle {
  margin-bottom: 15px;

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-0);
  font-weight: 700;
  text-transform: uppercase;
}

.footerTitle:hover,
.footerTitle:focus {
  text-decoration: none;
}

.footerLink {
  margin-top: 5px;

  /* Body/Body 1/Regural */
  font-family: Montserrat;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  font-style: normal;
  color: var(--n-color-text-dark);
}

.footerLink:hover,
.footerLink:focus {
  text-decoration: none;
}

.linkList {
  padding-left: 12px;
  list-style-type: none;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--n-size-4);
  margin: 0 auto;
}

.secondFooter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondFooterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: var(--n-color-white);
}

.secondFooterContainer > span {
  margin-top: 15px;
}

.secondFooterContainer > span,
.secondFooterContainer > span > a {
  width: 305px;

  /* Body/Body 2/Regular */
  font-family: Montserrat;
  font-size: var(--n-font-size-00);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-disabled);
  text-align: center;
}

.secondFooterContainer > span > a {
  text-decoration: underline;
}

.subscribeInput {
  background: var(--n-color-white);
  border-bottom: 1px solid var(--n-color-text-subtle);
  border-radius: var(--size-size-0, 0);
}

.followUs {
  display: flex;
  grid-column: 1 / -1;
  gap: var(--n-size-1);
  align-items: center;
  justify-self: center;

  margin-bottom: var(--n-size-4);

  span {
    color: var(--n-color-text-subtle);
  }

  @media (width >= 1024px) {
    justify-self: end;
  }
}

@media (width >= 350px) {
  .content {
    display: grid;
    grid-template-areas:
      'firstSection firstSection secondSection'
      'firstSection firstSection secondSection'
      'thirdSection thirdSection thirdSection';
  }
}

@media (width >= 768px) {
  .content {
    grid-template-columns: 1fr 1fr 2fr;
    padding: 0 25px;
  }
}

@media (width >= 1024px) {
  .content {
    grid-template-areas: 'firstSection secondSection thirdSection';
    padding: 0;
    border-bottom: 1px solid var(--n-color-border-active);
  }

  .thirdSection .privacy {
    text-align: start;
  }

  .secondFooterContainer > span {
    margin-top: 25px;
  }

  .thirdSection {
    border: none;
  }
}

@media (width >= 1280px) {
  .subscribeSection {
    grid-template-columns: 1fr auto;
  }

  .firstSection,
  .secondSection,
  .thirdSection {
    margin-bottom: var(--n-size-5);
  }
}
