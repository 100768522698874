@import url('../../../../styles/customMediaQueries.css');

.root {
  display: inline-block;

  &:first-child .link {
    padding-left: 0;
  }
}

.link {
  padding: 12px 10px;

  &:hover {
    color: var(--colorGrey700);
  }

  @media (width >= 768px) {
    padding: 12px;
  }
}

.media {
  width: 100%;
  margin-bottom: 0;
  border-radius: var(--radius-md);
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}
