.video {
  position: relative;
  overflow: hidden;
  border-radius: var(--radius-md);
}

.iframe {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}
